import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

export const TaskStore = {
	state: {
		tasks: [],
		selected: false,
		selectedTasks: [],
	},
	getters,
	mutations,
	actions,
};

export default TaskStore;
