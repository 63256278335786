<template>
	<div
		class="task"
		:class="[
			task.isSelected ? 'is-selected' : 'is-not-selected',
			task.compleated ? 'compleated' : '',
		]"
		@dblclick="select"
		:style="cssVars"
	>
		<div class="inpt-container">
			<Tooltip text="Mark Compleated">
				<i @click="toggleCompleated" class="fa-solid fa-circle-check"></i>
			</Tooltip>
		</div>
		<div class="txt-container">
			<h1>{{ task.text }}</h1>
		</div>
		<div class="btn-container">
			<div v-if="task.childTask && task.childTask.length > 0">
				<Tooltip text="Hide Children Task" v-if="!task.childrenHidden">
					<i @click="toggleChildren" class="fa-solid fa-list"></i>
				</Tooltip>
				<Tooltip text="Show Children Task" v-if="task.childrenHidden">
					<i @click="toggleChildren" class="fa-solid fa-list"></i>
				</Tooltip>
			</div>
			<Tooltip text="Move up">
				<i @click="moveTaskUp" class="fa-solid fa-angles-up"></i>
			</Tooltip>
			<Tooltip text="Move Down">
				<i @click="moveTaskDown" class="fa-solid fa-angles-down"></i>
			</Tooltip>
			<Tooltip text="Convert to child task">
				<i @click="makeChild" class="fa-solid fa-right-to-bracket"></i>
			</Tooltip>
			<Tooltip text="Delete Task">
				<i @click="deleteTask" class="fa-solid fa-trash-can trashCon"></i>
			</Tooltip>
		</div>
	</div>
	<div
		class="child-Container"
		v-if="!task.childrenHidden && task.childTask.length > 0"
	>
		<childTask v-for="c in task.childTask" :key="c.id" :task="c" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import childTask from "@/components/childTask.vue";
import Tooltip from "@/components/ToolTip.vue";
// import colors from "@/assets/colors";
export default {
	props: {
		task: Object,
	},
	components: {
		childTask,
		Tooltip,
	},
	data() {
		return {};
	},
	methods: {
		...mapActions([
			"removeTask",
			"selectTask",
			"moveUpInQue",
			"moveDownInQue",
			"changeToChild",
			"updateTasks",
		]),
		...mapGetters(["getSelected", "getColors"]),
		select() {
			this.selectTask(this.task);
			// console.log("T: ", this.task);
		},
		deleteTask() {
			this.removeTask(this.task);
		},
		moveTaskUp() {
			this.moveUpInQue(this.task);
		},
		moveTaskDown() {
			this.moveDownInQue(this.task);
		},
		makeChild() {
			this.changeToChild(this.task);
		},
		toggleCompleated() {
			this.task.compleated = !this.task.compleated;
			if (this.task.childTask && this.task.compleated) {
				this.task.childTask.forEach((c) => (c.compleated = true));
			}
			// console.log("T-compleated: ", this.task.compleated);
			this.updateTasks();
		},
		toggleChildren() {
			this.task.childrenHidden = !this.task.childrenHidden;
			this.updateTasks();
		},
	},
	created() {
		this.colors = this.getColors();
		console.log(this.task);
		// console.log(this.colors);
	},
	computed: {
		cssVars() {
			return {
				...this.getColors(),
			};
		},
	},
};
</script>

<style scoped>
.task {
	background-color: var(--taskMain);
	color: var(--textColor);
	/* border-color: var(--taskBorder); */
	display: flex;
	flex-direction: row;
	border: var(--taskBorder) solid 2px;
	border-radius: 10px;
	margin: 5px;
	font-size: 12px;
	padding: 1px;
	align-items: center;
	justify-content: center;
}
.task h1 {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;

	/*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
	-ms-user-select: none;
	user-select: none;
}
.task i {
	color: var(--icon);
	border: none;
	border-radius: 5px;
	/* width: 50px; */
	text-decoration: none !important;
	font-size: 30px;
	cursor: pointer;
	margin: 10px;
}
.btn-container {
	width: 20%;
}
.btn-container i {
	border: none;
	border-radius: 5px;
	/* width: 50px; */
	font-size: 30px;
	cursor: pointer;
	margin: 10px;
	align-content: flex-end;
}

.btn-container i:hover {
	color: var(--iconHover);
	/* font-size: 35px; */
}

.trashCon {
	margin: 0 35px !important;
}
.trashCon:hover {
	color: red !important;
}

.txt-container {
	width: 66%;
}
.is-selected {
	background-color: var(--taskSelectedBg);
	color: var(--taskSelectedText);
}
.is-not-selected {
	color: var(--textColor);
}
.child-Container {
	display: flex;
	flex-direction: column;
	align-content: flex-end;
	align-items: flex-end;
}

.inpt-container i {
	font-size: 45px;
	cursor: pointer;
}
.inpt-container i:hover {
	color: var(--iconHover);
}
.compleated {
	background-color: var(--taskCompleatedBg);
	/* background-color: green; */
	color: var(--taskCompleatedText);
}
.compleated h1 {
	text-decoration: line-through;
}
.compleated i {
	color: var(--taskMain);
}
</style>
