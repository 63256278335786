<template>
	<div
		class="childTask"
		:class="task.compleated ? 'compleated' : ''"
		:style="cssVars"
	>
		<div class="i-container">
			<Tooltip text="Mark Compleated">
				<i @click="toggleCompleated" class="fa-solid fa-circle-check"></i>
			</Tooltip>
		</div>
		<div class="txt-container">
			<h1>{{ task.text }}</h1>
		</div>
		<div class="btn-container">
			<Tooltip text="Move up">
				<i @click="moveTaskUp" class="fa-solid fa-angles-up"></i>
			</Tooltip>
			<Tooltip text="Move Down">
				<i @click="moveTaskDown" class="fa-solid fa-angles-down"></i>
			</Tooltip>
			<Tooltip text="Convert to Main task">
				<i @click="makeParent" class="fa-solid fa-right-from-bracket"></i>
			</Tooltip>
			<Tooltip text="Delete Task">
				<i @click="deleteTask" class="fa-solid fa-trash-can trashCon"></i>
			</Tooltip>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tooltip from "@/components/ToolTip.vue";
// import colors from "@/assets/colors";

export default {
	components: {
		Tooltip,
	},
	props: {
		task: Object,
	},
	data() {
		return {
			// colors,
		};
	},
	methods: {
		...mapActions([
			"removeTask",
			"selectTask",
			"moveUpInQue",
			"moveDownInQue",
			"changeToParent",
			"updateTasks",
		]),
		...mapGetters(["getSelected", "getColors"]),
		deleteTask() {
			this.removeTask(this.task);
		},
		moveTaskUp() {
			this.moveUpInQue(this.task);
		},
		moveTaskDown() {
			this.moveDownInQue(this.task);
		},
		makeParent() {
			this.changeToParent(this.task);
		},
		toggleCompleated() {
			this.task.compleated = !this.task.compleated;
			if (this.task.childTask && this.task.compleated) {
				this.task.childTask.forEach((c) => (c.compleated = true));
			}
			// console.log("T-compleated: ", this.task.compleated);
			this.updateTasks();
		},
	},
	created() {
		this.colors = this.getColors();
	},
	computed: {
		cssVars() {
			return { ...this.getColors() };
		},
	},
};
</script>

<style scoped>
.childTask {
	width: 85%;
	height: 30px;
	display: flex;
	flex-direction: row;
	color: var(--textColor);
	background-color: var(--childTaskColor);
	border: var(--childTaskBorder) solid 2px;
	border-radius: 10px;
	margin: 5px;
	font-size: 12px;
	/* text-decoration: underline; */
	padding: 1px;
	align-items: center;
	justify-content: center;
}
.childTask h1 {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;

	/*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
	-ms-user-select: none;
	user-select: none;
}

.childTask i {
	color: var(--icon);
	text-decoration: none !important;
	border: none;
	border-radius: 5px;
	/* width: 50px; */
	font-size: 25px;
	cursor: pointer;
	margin: 10px;
}
.btn-container {
	width: 33%;
}
.btn-container i {
	/* color: v-bind(textColor); */
	border: none;
	border-radius: 5px;
	/* width: 50px; */
	font-size: 25px;
	cursor: pointer;
	margin: 10px;
}

.btn-container i:hover {
	color: var(--iconHover);
	/* font-size: 35px; */
}
.trashCon {
	margin: 0 35px !important;
}
.trashCon:hover {
	color: red !important;
}
.txt-container {
	width: 66%;
}
.compleated {
	/* background-color: black; */
	/* background-color: green; */
	/* color: green; */
}
.compleated h1 {
	text-decoration: line-through;
}
.compleated i {
	/* color: v-bind(taskMain); */
}
.i-container i:hover {
	color: var(--iconHover);
}
</style>
