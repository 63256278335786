<template :style="cssVars">
	<!-- <div class="sidePanel"></div> -->
	<panel />
	<div class="btn-container">
		<copyTask />
		<settingsBtn />
		<removeAllTask />
	</div>
	<div class="top">
		<div class="type-container">
			<InputCapture />
		</div>
		<div v-if="tasks.length > 0">
			<Task v-for="t in tasks" :key="t" :task="t" />
		</div>
	</div>
</template>

<script>
import InputCapture from "@/components/InputCapture.vue";
import Task from "@/components/Task.vue";
import copyTask from "@/components/copyTask.vue";
import removeAllTask from "@/components/removeAllTask.vue";
import settingsBtn from "@/components/SettingsButton.vue";
import Panel from "@/components/colorSettingsPanel.vue";

import { mapGetters, mapActions } from "vuex";
import TabHandler from "@/components/TabHandler.vue";
export default {
	name: "App",
	components: {
		InputCapture,
		Task,
		copyTask,
		removeAllTask,
		settingsBtn,
		Panel,
		TabHandler,
	},
	data() {
		return {
			show: false,
		};
	},
	computed: {
		...mapGetters({ tasks: "getAllTasks", selectedTab: "getSelectedTab" }),
		cssVar() {
			return { ...this.getColors() };
		},
	},
	methods: {
		...mapActions([
			"logTaskState",
			"loadThemes",
			"getColors",
			"loadTabs",
			"loadTasks",
		]),
		...mapGetters([, "getAllTasks"]),
	},
	created() {
		this.loadThemes();
		this.loadTabs();
		this.loadTasks();
		this.getAllTasks();
		this.logTaskState();
	},
};
</script>

<style>
/* html {
	background-color: #252a45;
} */
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	margin-top: 60px;
}
.type-container {
	position: relative;
	width: 90%;
	height: 25%;
}
.top {
	display: flex;
	flex-direction: column;
}
.btn-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.sidePanel {
	position: relative;
}
</style>
