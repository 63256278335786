<template>
	<div class="panel" :style="cssVar" v-if="getPanelActive">
		<div class="themes">
			<div class="themes-Title-Holder">
				<h3 class="theme-title">Themes</h3>
				<i @click="togglePanelActivation" class="fa-solid fa-square-xmark"></i>
			</div>

			<div class="themes-Holder">
				<button
					class="theme-button"
					@click="setColorTheme(t)"
					v-for="t in getThemes"
					:key="t"
				>
					{{ t }}
				</button>
			</div>
		</div>
		<div class="colors">
			<h3>All Colors</h3>
			<div v-for="c in colorsObj" :key="c.name" class="colorHolder">
				<label for="clr">{{ c.name }} </label>
				<input
					type="color"
					:name="c.name"
					:value="c.color"
					id="clr"
					@change="changeColor"
				/>
			</div>
		</div>
		<div class="bottom">
			<Tooltip text="Save Theme">
				<i @click="saveTheme" class="fa-solid fa-floppy-disk"></i>
			</Tooltip>
			<Tooltip text="Add new Theme">
				<i @click="addTheme" class="fa-solid fa-plus"></i>
			</Tooltip>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Tooltip from "@/components/ToolTip.vue";
export default {
	components: {
		Tooltip,
	},
	methods: {
		...mapActions([
			"setColor",
			"addTheme",
			"togglePanelActivation",
			"saveTheme",
			"setColorTheme",
		]),
		...mapGetters(["getColors", "getColorsObj"]),
		changeColor(e) {
			let obj = {
				name: e.target.name,
				value: e.target.value,
			};
			this.setColor(obj);
		},
	},
	created() {
		this.colors = this.getColors();
		this.colorsObj = this.getColorsObj();
		// this.Themes = this.getThemes();
	},
	computed: {
		...mapGetters(["getPanelActive", "getThemes"]),
		cssVar() {
			return { ...this.getColors() };
		},
	},
};
</script>

<style>
html {
	background: #252a45;
}
.panel {
	padding: 5px;
	height: 90%;
	/* width: 25%; */
	position: absolute;
	z-index: 10;
	top: 30px;
	left: 0;
	border-radius: 30px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background-color: var(--settingsBgColor);
	border: 2px solid var(--settingsBorderColor);
	color: var(--settingsTextColor);

	display: flex;
	flex-direction: column;
}
.panel i:hover {
	color: var(--iconHover);
}
.themes {
	display: flex;
	flex-direction: column;
	height: 20%;
	margin: 5px;
}
.theme-title {
	height: 10%;
	width: 80%;
	text-align: center;
	font-size: 24px;
}
.themes-Holder {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 80%;
	border-radius: 15px;
	border-bottom: 5px solid black;
	background-color: var(--taskMain);
	padding: 2px;
	justify-content: center;
	align-items: center;
}
.themes-Title-Holder {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: space-evenly;
}
.themes-Title-Holder i {
	font-size: 32px;
	color: var(--icon);
}
.colors {
	display: block;
	margin: 3px;
	overflow-y: auto;
}
.colors::-webkit-scrollbar {
	width: 5px; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
::-webkit-scrollbar-thumb {
	background: var(--taskBorder);
}
.colorHolder {
	padding: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 25px;
	text-align: left;
	border-bottom: 2px solid black;
}
.colorHolder label {
	margin: 3px 5px;
}
.colorHolder input {
	border: 2px solid black;
	outline: none;
	width: 55px;
	-webkit-appearance: none;
	padding: 0;
}
.colorHolder input::-webkit-color-swatch-wrapper {
	padding: 0;
}
.colorHolder input::-webkit-color-swatch {
	border: none;
}
.colorHolder input:hover {
	border-color: white;
}
.bottom {
	margin: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.bottom i {
	font-size: 50px;
	color: var(--icon);
	margin: 10px;
}
.bottom i:hover {
	color: var(--iconHover);
}
.theme-button {
	width: 85%;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	color: black;
	margin: 2px;
}
.theme-button:hover {
	color: var(--iconHover);
	background-color: black;
}
</style>
