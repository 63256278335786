<template>
	<div>
		<ToolTip text="Color Settings">
			<i @click="togglePanel" class="fa-solid fa-gear"></i>
		</ToolTip>
	</div>
</template>

<script>
import colors from "@/assets/colors";
import { mapActions } from "vuex";
import ToolTip from "@/components/ToolTip.vue";
export default {
	components: {
		ToolTip,
	},
	data() {
		return {
			...colors,
		};
	},
	methods: {
		...mapActions(["togglePanelActivation"]),
		togglePanel() {
			this.togglePanelActivation();
		},
	},
};
</script>

<style scoped>
i {
	font-size: 55px;
	color: v-bind(icon);
	margin: 4px;
}
i:hover {
	color: whitesmoke;
	cursor: pointer;
}
</style>
