export const mutations = {
	addTaskToState(state, task) {
		state.tasks.push(task);
	},
	removeTaskFromState(state, task) {
		//   state.tasks = state.tasks.filter((t) => t.id !== task.id);
		if (state.selected.id === task.id) state.selected = false;
		state.tasks = state.tasks.filter((t) => t.id !== task.id);
		if (state.tasks.length <= 0) state.selected = false;
	},
	addTaskToParent(state, task) {
		if (state.selected.childTask) {
			state.selected.childTask.push(task);
		} else {
			state.selected.childTask = [task];
		}
		console.log("Ding Child : ", state.selected);
	},
	removeTaskFromParent(state, task) {
		let arr = state.tasks;
		arr.forEach((t) => {
			if (t.id === task.parentId) {
				t.childTask = t.childTask.filter((c) => c.id !== task.id);
			}
		});

		state.tasks = arr;
	},
	setSelectedTask(state, task) {
		if (state.selected) {
			state.selected.isSelected = false;
		}
		task.isSelected = true;
		state.selected = task;
	},
	unSetSelectedTask(state, task) {
		if (state.selected) {
			state.selected.isSelected = false;
		}
		state.selected = false;
		task.isSelected = false;
	},
	moveForwardInArray(state, task) {
		let idx = 0;
		let newArr = state.tasks;
		newArr.forEach((t, i) => {
			if (t.id === task.id) {
				idx = i;
			}
		});
		if (idx === 0) return;
		let t = newArr.splice(idx, 1);
		newArr.splice(idx - 1, 0, t[0]);
		state.tasks = newArr;
	},
	moveBackwardsInArray(state, task) {
		let idx = 0;
		let newArr = state.tasks;
		newArr.forEach((t, i) => {
			if (t.id === task.id) {
				idx = i;
			}
		});
		if (idx === state.tasks.length - 1) return;
		let t = newArr.splice(idx, 1);
		newArr.splice(idx + 1, 0, t[0]);
		state.tasks = newArr;
	},
	moveChildForwardInArray(state, task) {
		let idx = 0;
		let parent = state.tasks.find((p) => p.id === task.parentId);
		let newArr = parent.childTask;
		newArr.forEach((t, i) => {
			if (t.id === task.id) {
				idx = i;
			}
		});
		if (idx === 0) return;
		let t = newArr.splice(idx, 1);
		newArr.splice(idx - 1, 0, t[0]);
		parent.childTask = newArr;
		// console.log("T: ", t[0], "Parent: ", parent);
	},
	moveChildBackwardsInArray(state, task) {
		let idx = 0;
		let parent = state.tasks.find((p) => p.id === task.parentId);
		let newArr = parent.childTask;
		newArr.forEach((t, i) => {
			if (t.id === task.id) {
				idx = i;
			}
		});
		if (idx === parent.childTask.length - 1) return;
		let t = newArr.splice(idx, 1);
		newArr.splice(idx + 1, 0, t[0]);
		parent.childTask = newArr;
		// console.log("T: ", t[0], "Parent: ", parent);
	},
	clearTaskArray(state) {
		state.tasks = state.tasks.filter((t) => {
			if (!t.compleated) {
				if (t.childTask) {
					t.childTask = t.childTask.filter((ct) => !ct.compleated);
				}
				return t;
			}
		});

		let parsed = JSON.stringify(state.tasks);
		localStorage.setItem("tasks", parsed);
	},
	writeTaskToStorage(state) {
		console.log("Task Written to storage");
		let parsed = JSON.stringify(state.tasks);
		localStorage.setItem("tasks", parsed);
	},
	clearTabTasks(state, tab) {
		state.tasks = state.tasks.filter((t) => t.tab !== tab);
		console.log("Tasks Cleard by tab");
	},
	updateFilterdTasks(state, tab) {
		if (!tab) {
			state.selectedTasks = state.tasks;
			return;
		}
		state.selectedTasks = state.tasks.filter((t) => t.tab === tab);
	},
	loadTasks(state) {
		if (localStorage.getItem("tasks")) {
			let t = JSON.parse(localStorage.getItem("tasks"));
			t.forEach((tsk) => {
				if (tsk.isSelected) {
					tsk.isSelected = false;
				}
			});
			let parsed = JSON.stringify(t);
			localStorage.setItem("tasks", parsed);
		}
	},
	logState(state) {
		console.log("State tasks: ", state.tasks);
		console.log("State selected: ", state.selected);
		console.log("State selected Tasks: ", state.selectedTasks);
	},
	testTaskMut() {
		console.log("Mutation in Task");
	},
};
export default mutations;
