<template>
	<div class="tabHolder">
		<div>
			<ToolTip text="Add Tab">
				<i @click="addTab" id="add" class="fa-solid fa-square-plus"></i>
			</ToolTip>
			<ToolTip text="Remove Selected Tab">
				<i @click="removeTab" id="remove" class="fa-solid fa-square-minus"></i>
			</ToolTip>
		</div>
		<button
			@click="selectTab(t)"
			v-for="t in tabsArr"
			:key="t"
			:class="selected === t ? 'selected' : ''"
		>
			{{ t }}
		</button>
	</div>
</template>

<script>
import ToolTip from "@/components/ToolTip.vue";
import { mapGetters, mapActions } from "vuex";
export default {
	props: {
		createTab: Function,
	},
	components: {
		ToolTip,
	},
	data() {
		return {};
	},
	methods: {
		...mapActions(["addTabAction", "selectTabAction", "removeTabAction"]),
		addTab() {
			let r = this.createTab();
			console.log("R: ", r, r.length);
			if (r.length === 0) return;
			this.addTabAction(r);
			console.log("Tabs: ", this.tabsArr, "S: ", this.selected);
		},
		removeTab() {
			this.removeTabAction();
		},
		selectTab(t) {
			// console.log("T: ", this.selected);
			this.selectTabAction(t);
		},
	},
	computed: {
		...mapGetters({ tabsArr: "getAllTabs", selected: "getSelectedTab" }),
	},
};
</script>

<style scoped>
.tabHolder {
	display: flex;
	flex-direction: row;
	margin: 3px;
}
.tabHolder i {
	font-size: 30px;
	margin: 2px;
}
.tabHolder button {
	width: 10%;
	margin: 3px;
	border: 1px solid aquamarine;
	background-color: rgb(71, 175, 140);
	border-radius: 2px;
}
.selected {
	background-color: aquamarine !important;
}
#add:hover {
	color: whitesmoke !important;
	cursor: pointer;
}
#remove:hover {
	color: red !important;
	cursor: pointer;
}
</style>
