import { createStore } from "vuex";
import TabsStore from "./Tabs-store";
import TaskStore from "./Task-store";
import SettingsStore from "./Settings-Store";
// {
// 	id: "-1A",
// 	text: "this is a test",
// 	childTask: false,
// 	isSelected: false,
// 	isChild: false,
// 	parentId: false,
// 	compleated:false,
//   },

export default createStore({
	modules: {
		SettingsStore,
		TabsStore,
		TaskStore,
	},
});
