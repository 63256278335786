export const actions = {
	loadTabs({ commit }) {
		if (localStorage.getItem("tabs")) {
			commit("loadTabStorage");
			console.log("Loaded Tabs");
		} else {
			commit("createDefaultTabStorage");
			console.log("created Tabs");
		}
	},
	addTabAction({ commit }, tab) {
		commit("addTabToState", tab);
		commit("setTabStorage");
	},
	removeTabAction({ commit }) {
		// return console.log("issues here with removing task when only 1 tab");
		commit("removeTabFromState");
	},
	selectTabAction({ commit }, tab) {
		commit("selectTab", tab);
		commit("setTabStorage");
	},
	updateFilterdTasks({ commit }) {
		console.log("Tasks update");
		commit("updateTasks");
	},
};

export default actions;
