export const getters = {
	getAllTasks(state, getters) {
		if (localStorage.getItem("tasks")) {
			let T = getters.getSelectedTab;
			state.tasks = JSON.parse(localStorage.getItem("tasks"));
			state.selectedTasks = state.tasks.filter((t) => t.tab === T);
			// getters.getSelected;
		}
		return state.selectedTasks;
	},
	getSelectedTask(state) {
		return state.selected;
	},
	getTasksText(state) {
		let text = "";
		state.tasks.forEach((t) => {
			let a = [];
			if (t.childTask) {
				t.childTask.forEach((c) => {
					if (c.compleated) {
						a.push(`-- ${c.text}\n`);
					}
				});
			}
			if (t.compleated || a.length > 0) {
				text += `${t.text}\n`;
				if (a.length > 0) {
					a.forEach((txt) => (text += txt));
				}
			}
		});
		return text;
	},
};
export default getters;
