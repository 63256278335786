export const mutations = {
	changePanelActive(state) {
		state.isActive = !state.isActive;
	},
	changeComponetColor(state, obj) {
		console.log("Color Change");
		let c = Object.assign({}, state.themes[state.currentTheme]);
		// c = JSON.parse(JSON.stringify(c));
		console.log(state.co);
		for (const key in c) {
			if (c[key].name === obj.name) {
				c[key].color = obj.value;
				continue;
			}
		}
		state.colors = { ...c };
	},
	changeAppTheme(state, theme) {
		console.log("Change App theme", theme);
		console.log("Has theme");
		if (theme === state.currentTheme) {
			let c = Object.assign({}, state.themes[state.currentTheme]);
			state.colors = { ...c };
		}
		state.currentTheme = theme;
		let c = Object.assign({}, state.themes[theme]);
		state.colors = { ...c };

		let obj = { currentTheme: theme, themes: state.themes };
		let parsed = JSON.stringify(obj);
		localStorage.setItem("themes", parsed);
	},
	createNewTheme(state) {
		console.log("create New Theme");
		let n = Object.keys(state.themes).length;
		let c = Object.assign({}, state.colors);
		state.themes[`Preference ${n}`] = { ...c };
		state.currentTheme = `Preference ${n}`;
	},
	saveCurrentTheme(state) {
		console.log("Save Theme");
		if (state.currentTheme !== "default") {
			let c = Object.assign({}, state.colors);
			state.themes[state.currentTheme] = { ...c };
		} else {
			console.log("Bingo");
			let n = Object.keys(state.themes).length;
			let c = Object.assign({}, state.colors);
			state.themes[`Preference ${n}`] = { ...c };
			state.currentTheme = `Preference ${n}`;
			state.colors = { ...c };
		}
		let obj = { currentTheme: state.currentTheme, themes: state.themes };
		let parsed = JSON.stringify(obj);
		localStorage.setItem("themes", parsed);
	},
	loadThemesFromLocal(state) {
		console.log("Load Theme");
		if (localStorage.getItem("themes")) {
			let obj = JSON.parse(localStorage.getItem("themes"));
			state.themes = obj.themes;
			state.currentTheme = obj.currentTheme;
			let c = Object.assign({}, state.themes[state.currentTheme]);
			state.colors = { ...c };
		} else {
			let obj = { currentTheme: state.currentTheme, themes: state.themes };
			let parsed = JSON.stringify(obj);
			localStorage.setItem("themes", parsed);
			let c = Object.assign({}, state.themes[state.currentTheme]);
			state.colors = { ...c };
		}
	},
};

export default mutations;
