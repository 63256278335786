export const getters = {
	getColors(state) {
		let colorsObj = {};
		for (const k in state.colors) {
			colorsObj[k.toString()] = state.colors[k].color;
		}
		// console.log("ColorsObjs: ", colorsObj);
		return colorsObj;
	},
	getColorsObj(state) {
		return state.colors;
	},
	getPanelActive(state) {
		return state.isActive;
	},
	getThemes(state) {
		let t = Object.keys(state.themes);
		return t;
	},
};

export default getters;
