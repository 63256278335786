import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

const defThemes = {
	"--inputTextColor": { name: "Input Text Color", color: "#f5deb3" },
	"--bColor": { name: "Page Background", color: "#2f3d55" },
	"--taskMain": { name: "Parent Task ", color: "#557f8e" },
	"--taskBorder": { name: "Parent Task Border", color: "#79a5af" },
	"--textColor": { name: "Task Text Color", color: "#000000" },
	"--taskSelectedBg": {
		name: "Selected Task Background",
		color: "#79a5af",
	},
	"--taskSelectedText": { name: "Selected task Text", color: "#ffffff" },
	"--taskCompleatedBg": {
		name: "Compleated Task Background",
		color: "#000000",
	},
	"--taskCompleatedText": {
		name: "Compleated Task Text",
		color: "#078c14",
	},

	"--icon": { name: "Task Icon Color", color: "#79a5af" },
	"--iconHover": { name: "Task Icon Hover", color: "#ffffff" },
	"--childTaskColor": { name: "Child Task ", color: "#3b4c5f" },
	"--childTaskBorder": { name: "Child Task Border", color: "#557f8e" },
	"--taskRed": { name: "Warning Color", color: "#ff1100" },
	"--settingsBgColor": { name: "Settings Panel", color: "#3b4c5f" },
	"--settingsBorderColor": { name: "Settings Border", color: "#000000" },
	"--settingsTextColor": { name: "Settings Text", color: "#000000" },
};

export const SettingsStore = {
	state: {
		colors: {},
		themes: { default: { ...defThemes } },
		currentTheme: "default",
		isActive: false,
	},
	getters,
	mutations,
	actions,
};

export default SettingsStore;
