<template>
	<Tooltip text="Copy Tasks to ClipBoard">
		<i @click="copyTask" class="fa-solid fa-clipboard"></i>
	</Tooltip>
</template>

<script>
import { mapGetters } from "vuex";
import colors from "@/assets/colors";
import Tooltip from "@/components/ToolTip.vue";
export default {
	components: {
		Tooltip,
	},
	methods: {
		...mapGetters(["getTasksText"]),
		copyTask() {
			// let int = document.createElement("input");
			// int.value = "hihihih";
			// int.select();
			navigator.clipboard.writeText(this.getTasksText());
			// alert("copied");
		},
	},
	data() {
		return {
			...colors,
		};
	},
};
</script>

<style scoped>
i {
	font-size: 55px;
	color: v-bind(icon);
	margin: 4px;
}
i:hover {
	color: v-bind(iconHover);
	cursor: pointer;
}
</style>
