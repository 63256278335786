const colors = {
	bColor: "#2f3d55",
	taskMain: "#557f8e",
	taskBorder: "#79a5af",
	taskSelected: "#79a5af",
	textColor: "black",
	icon: "#79a5af",
	iconHover: "white",
	childTaskColor: "#3b4c5f",
	childTaskBorder: "#557f8e",
};

// https://lospec.com/palette-list/blue-newspaper

export default colors;
