export const actions = {
	togglePanelActivation({ commit }) {
		commit("changePanelActive");
	},
	setColor({ commit }, obj) {
		commit("changeComponetColor", obj);
	},
	setColorTheme({ commit }, theme) {
		commit("changeAppTheme", theme);
	},
	addTheme({ commit }) {
		commit("createNewTheme");
		commit("saveCurrentTheme");
		commit("loadThemesFromLocal");
	},
	saveTheme({ commit }) {
		commit("saveCurrentTheme");
		commit("loadThemesFromLocal");
	},
	loadThemes({ commit }) {
		commit("loadThemesFromLocal");
	},
};
export default actions;
