import { state } from "vuex";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

export const TabsStore = {
	state: {
		tabs: [],
		selectedTab: "one",
	},
	getters,
	mutations,
	actions,
};

export default TabsStore;
