export const mutations = {
	createDefaultTabStorage(state) {
		state.tabs = ["one"];
		state.selectedTab = "one";
		let obj = { tabs: ["one"], selectedTab: "one" };
		let parsed = JSON.stringify(obj);
		localStorage.setItem("tabs", parsed);
	},
	loadTabStorage(state) {
		let t = JSON.parse(localStorage.getItem("tabs"));
		if (t.tabs.length <= 0) {
			this.commit("createDefaultTabStorage");
			return;
		}
		state.tabs = t.tabs;
		state.selectedTab = t.selectedTab;
	},
	setTabStorage(state) {
		let obj = { tabs: state.tabs, selectedTab: state.selectedTab };
		let parsed = JSON.stringify(obj);
		localStorage.setItem("tabs", parsed);
	},
	removeTabFromState(state) {
		if (state.tabs.length === 1) return;
		let currT = state.selectedTab;
		let a = state.tabs.filter((t) => t !== state.selectedTab);
		state.tabs = a;
		state.selectedTab = state.tabs[state.tabs.length - 1];
		this.commit("setTabStorage");
		this.commit("clearTabTasks", currT);
		this.commit("writeTaskToStorage");
	},
	addTabToState(state, tab) {
		if (state.tabs.length >= 9) {
			return;
		}
		if (state.tabs.includes(tab)) {
			alert("Tabs cannot have the same name");
			return;
		}
		state.tabs.push(tab);
	},
	selectTab(state, tab) {
		if (!tab) {
			state.selectedTab = state.tabs[state.tabs.length - 1];
		} else {
			state.selectedTab = tab;
		}
		this.commit("updateFilterdTasks", tab);
	},
	updateTasks(state) {
		this.commit("updateFilterdTasks", state.selectTab);
	},
};

export default mutations;
