export const actions = {
	addTask({ commit, state }, task) {
		if (state.selected) {
			state.selected.childrenHidden = false;
			task.isChild = true;
			task.parentId = state.selected.id;

			commit("addTaskToParent", task);
		} else {
			commit("addTaskToState", task);
		}
		commit("writeTaskToStorage");
		commit("logState");
	},
	removeTask({ commit }, task) {
		//   console.log("Bingo", task);
		if (task.isChild) {
			commit("removeTaskFromParent", task);
		} else {
			commit("removeTaskFromState", task);
		}
		commit("writeTaskToStorage");
	},
	selectTask({ commit }, task) {
		if (!task.isSelected) {
			commit("setSelectedTask", task);
			// console.log("Select Task");
		} else {
			commit("unSetSelectedTask", task);
			// console.log("Unselect Task");
		}
		commit("writeTaskToStorage");
	},
	moveUpInQue({ commit }, task) {
		if (task.isChild) {
			commit("moveChildForwardInArray", task);
		} else {
			commit("moveForwardInArray", task);
		}
		commit("writeTaskToStorage");
	},
	moveDownInQue({ commit }, task) {
		if (task.isChild) {
			commit("moveChildBackwardsInArray", task);
		} else {
			commit("moveBackwardsInArray", task);
		}
		commit("writeTaskToStorage");
	},
	changeToParent({ commit }, task) {
		// console.log("bingo");
		commit("removeTaskFromParent", task);
		task.parentId = false;
		task.isChild = false;
		commit("addTaskToState", task);
		commit("writeTaskToStorage");
	},
	changeToChild({ commit, state }, task) {
		if (!state.selected) return;
		if (task.id === state.selected.id) return;
		commit("removeTaskFromState", task);
		task.isChild = true;
		task.parentId = state.selected.id;
		commit("addTaskToParent", task);
		commit("writeTaskToStorage");
	},
	clearTasks({ commit }) {
		commit("clearTaskArray");
	},
	updateTasks({ commit }) {
		commit("writeTaskToStorage");
	},
	loadTasks({ commit }) {
		commit("loadTasks");
		// commit("writeTaskToStorage");
	},
	logTaskState({ commit }) {
		commit("logState");
	},
};

export default actions;
