<template>
	<div class="holder">
		<ToolTip text="remove all Finished Tasks">
			<i @click="removeAllTask" class="tooltip fa-solid fa-calendar-xmark"></i>
		</ToolTip>
		<!-- <span class="tooltiptext">Remove All tasks</span> -->
	</div>
</template>

<script>
import { mapActions } from "vuex";
import colors from "@/assets/colors";
import ToolTip from "@/components/ToolTip.vue";

export default {
	components: {
		ToolTip,
	},
	data() {
		return {
			...colors,
		};
	},
	methods: {
		...mapActions(["clearTasks"]),
		removeAllTask() {
			this.clearTasks();
		},
	},
};
</script>

<style scoped>
.holder {
	margin: 4px 45px;
}
i {
	font-size: 55px;
	color: v-bind(icon);
}
i:hover {
	color: red;
	cursor: pointer;
}
</style>
