<template>
	<div class="container" :style="varCss">
		<h5 v-if="text.length > 0">{{ text }}<span>&lt;</span></h5>
		<h6 v-if="text.length === 0">Start typing....</h6>
	</div>
	<div>
		<tabhandler :createTab="createTab" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tabhandler from "@/components/TabHandler.vue";
export default {
	components: {
		Tabhandler,
	},
	data() {
		return {
			text: "",
			parent: false,
		};
	},
	created() {
		document.addEventListener("keydown", this.updateText);

		document.onfocus = () => {
			console.log("oh hii");
		};
		document.onblur = () => {
			this.text = this.text;
		};
	},
	methods: {
		...mapActions(["addTask", "updateFilterdTasks"]),
		...mapGetters(["getColors", "getSelectedTab"]),
		onchange() {
			console.log("Bomm change!");
		},
		updateText(e) {
			//   console.log("E: ", e);
			e.preventDefault();
			if (e.key === "Enter") {
				if (this.text.length === 0) return;
				this.createTask();
				this.reset();
				return;
			}
			if (e.key === "Backspace") {
				this.text = this.text.slice(0, -1);
				return;
			}
			if (e.key === "Escape") {
				this.reset();
				return;
			}
			if (e.key === " ") {
				// e.preventDefault();
				if (this.text.length === 0) return;
				// console.log("T: ", this.text);
			}
			if (e.key === "Shift") return;
			if (e.key === "Tab") return;
			this.text += e.key;
		},
		createTab() {
			let t = this.text;
			this.reset();
			console.log("T: ", t);
			return t;
		},
		createTask() {
			let TaskObj = {
				text: this.text,
				id: this.text.charAt(0) + Math.round(Math.random() * 10000),
				childTask: false,
				isSelected: false,
				isChild: false,
				childrenHidden: true,
				tab: this.getSelectedTab(),
			};
			this.addTask(TaskObj);
			this.updateFilterdTasks();
			console.log("Created Task: ", TaskObj);
		},
		reset() {
			this.text = "";
		},
	},
	computed: {
		varCss() {
			return { ...this.getColors() };
		},
	},
};
</script>

<style scoped>
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 100%;
	height: 35px;
	margin: 10px;
	font-size: 28px;
	color: var(--inputTextColor);
	text-align: center;
}
</style>
